import de from "./de";
import en from "./en";
import es from "./es";
import it from "./it";
import ja from "./ja";
import pt from "./pt";

export default {
  de,
  en,
  es,
  it,
  ja,
  pt,
};
